// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { colPadding, minToolColumns, minToolRows, numColumns, rowSize } from '@/common/shared.js'

const squareDistanceThreshold = 40000

export default {
  setup({ isDragging, top, left, heightInPixels, widthInPixels, fullscreen, forceRedraw, props }) {
    const img = new Image()
    let scrollY = 0
    let startMouseX = 0
    let startMouseY = 0
    let mouseX = 0
    let mouseY = 0
    let startLeft = 0
    let startTop = 0
    let startWidthInPixels = 0
    let startHeightInPixels = 0
    let sessionContainerHeightSnapshot = 0
    let verticalScrollManagerInterval = null

    const scrollMonitorDelayMillis = 25
    const scrollSpeed = 25
    const verticalScrollRegionHeight = 64
    const verticalScrollManager = () => {
      if (window.scrollY > 0 && mouseY <= verticalScrollRegionHeight) {
        startMouseY += scrollSpeed
        window.scrollBy({
          top: -scrollSpeed,
          left: 0,
          behavior: 'auto',
        })
        setTimeout(() => moveTool(0, -scrollSpeed), 5)  // Hack - make this smoother.
        return
      }
      const windowBottomPixelY = window.scrollY + window.innerHeight
      if (windowBottomPixelY < sessionContainerHeightSnapshot && mouseY >= window.innerHeight - verticalScrollRegionHeight) {
        startMouseY -= scrollSpeed
        window.scrollBy({
          top: scrollSpeed,
          left: 0,
          behavior: 'auto',
        })
        setTimeout(() => moveTool(0, scrollSpeed), 5)  // Hack - make this smoother.
      }
    }

    // Firefox has a 12-year-old bug where it doesn't send mouse coordinates for drag events.
    // https://bugzilla.mozilla.org/show_bug.cgi?id=505521
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

    // After extensive debugging, I have concluded that the initial DragEvent
    // values (from onDragStart) for both clientX/clientY and pageX/pageY
    // are not consistent with the subsequent values sent to onDrag.
    // (I've seen discrepancies of over 100 pixels vertically with only
    //  a single pixel move of the mouse!)
    // Therefore, it is not useful to save the mouseX/mouseY and
    // startMouseX/startMouseY in the onDragStart method, but instead
    // they need to be captured on the very first onDrag call.

    const onDragStart = (event) => {
      event.dataTransfer.setDragImage(img, 0, 0)
      if (fullscreen.value) { return }
      // Workaround: passing sessionContainerHeight destroys interactivity.
      // Instead, get the position of the .bottom-padding element.
      const bottomPaddingEl = document.querySelector('.bottom-padding')
      sessionContainerHeightSnapshot = parseInt(bottomPaddingEl.style.top, 10)

      scrollY = props.getToolScrollY()
      // Workaround: set these values to "undefined" to flag
      // that they should be set in the first call to onDrag.
      mouseX = undefined // event.clientX
      mouseY = undefined // event.clientY
      startMouseX = mouseX
      startMouseY = mouseY
      startLeft = left.value
      startTop = top.value
      startWidthInPixels = widthInPixels.value
      startHeightInPixels = heightInPixels.value
      isDragging.value = true
      verticalScrollManagerInterval = setInterval(verticalScrollManager, scrollMonitorDelayMillis)
      props.onToolDragStart({ toolId: props.toolId })

      if (isFirefox) {
        const body = document.querySelector('body')
        const draggingElement = event.target
        body.ondragover = (event) => {
          draggingElement.dispatchEvent(new MouseEvent('drag', event))
        }
      }
    }

    const moveTool = (deltaX, deltaY) => {
      left.value = startLeft + deltaX
      top.value = startTop + deltaY
    }

    const onDrag = (event) => onAllDragEvents(event, moveTool)

    const onDragEnd = (event) => {
      if (fullscreen.value) {
        event.preventDefault()
        return
      }
      left.value = startLeft
      top.value = startTop
      props.setToolScrollY(scrollY)
      onResizeEnd(event)
    }

    const onAllDragEvents = (event, fn) => {
      event.preventDefault()
      // Workaround: Use the first call to onDrag to set the mouseX/mouseY values.
      if (startMouseX === undefined) {
        if (event.clientX === 0 && event.clientY === 0) { return }
        mouseX = event.clientX
        mouseY = event.clientY
        startMouseX = mouseX
        startMouseY = mouseY
        return
      }

      if (fullscreen.value) { return }
      const squareDistance = (mouseX - event.clientX) * (mouseX - event.clientX) + (mouseY - event.clientY) * (mouseY - event.clientY)
      if (squareDistance > squareDistanceThreshold) { return }
      if (!isFirefox && event.screenX === 0 && event.screenY === 0) { return } // browser flakiness
      mouseX = event.clientX
      mouseY = event.clientY
      const deltaX = mouseX - startMouseX
      const deltaY = mouseY - startMouseY

      fn(deltaX, deltaY)
      props.onToolDrag({ toolId: props.toolId, left: left.value, top: top.value, widthInPixels: widthInPixels.value, heightInPixels: heightInPixels.value })
    }

    const checkBounds = ({ newLeft, newTop, newWidth, newHeight }) => {
      newLeft = newLeft || startLeft
      newWidth = newWidth || startWidthInPixels
      newHeight = newHeight || startHeightInPixels
      newTop = newTop || startTop
      if (newLeft < colPadding) {
        newWidth -= colPadding - newLeft
        newLeft = colPadding
      }
      if (newLeft > (numColumns - minToolColumns) * props.columnSize + colPadding) {
        newLeft = (numColumns - minToolColumns) * props.columnSize + colPadding
      }
      if (newLeft + newWidth > window.innerWidth - 1.5 * colPadding) {
        newWidth = window.innerWidth - 1.5 * colPadding - newLeft
      }
      if (newWidth < minToolColumns * props.columnSize - 2 * colPadding) {
        newWidth = minToolColumns * props.columnSize - 2 * colPadding
      }
      if (newHeight < minToolRows * rowSize) { newHeight = minToolRows * rowSize }
      left.value = newLeft
      top.value = newTop
      widthInPixels.value = newWidth
      heightInPixels.value = newHeight
    }

    const onResizeBottomLeft = (event) => onAllDragEvents(event, (dx, dy) => {
      const newLeft = startLeft + dx
      const newWidth = startWidthInPixels - dx
      const newHeight = startHeightInPixels + dy
      checkBounds({ newLeft, newWidth, newHeight })
    })

    const onResizeBottomRight = (event) => onAllDragEvents(event, (dx, dy) => {
      const newWidth = startWidthInPixels + dx
      const newHeight = startHeightInPixels + dy
      checkBounds({ newWidth, newHeight })
    })

    const onResizeTopLeft = (event) => onAllDragEvents(event, (dx, dy) => {
      const newLeft = startLeft + dx
      const newWidth = startWidthInPixels - dx
      const newHeight = startHeightInPixels - dy
      const newTop = startTop + dy
      checkBounds({ newLeft, newTop, newWidth, newHeight })
    })

    const onResizeTopRight = (event) => onAllDragEvents(event, (dx, dy) => {
      const newWidth = startWidthInPixels + dx
      const newHeight = startHeightInPixels - dy
      const newTop = startTop + dy
      checkBounds({ newTop, newWidth, newHeight })
    })

    const onResizeLeft = (event) => onAllDragEvents(event, (dx) => {
      const newLeft = startLeft + dx
      const newWidth = startWidthInPixels - dx
      checkBounds({ newLeft, newWidth })
    })

    const onResizeRight = (event) => onAllDragEvents(event, (dx) => {
      const newWidth = startWidthInPixels + dx
      checkBounds({ newWidth })
    })

    const onResizeBottom = (event) => onAllDragEvents(event, (_, dy) => {
      const newHeight = startHeightInPixels + dy
      checkBounds({ newHeight })
    })

    const onResizeTop = (event) => onAllDragEvents(event, (_, dy) => {
      const newHeight = startHeightInPixels - dy
      const newTop = startTop + dy
      checkBounds({ newTop, newHeight })
    })

    const onResizeEnd = (event) => {
      event.preventDefault()
      if (fullscreen.value) { return }
      clearInterval(verticalScrollManagerInterval)
      verticalScrollManagerInterval = null
      isDragging.value = false
      let dropEffect = event.dataTransfer.dropEffect
      if (dropEffect === 'none' && event.srcElement === event.target) {
        // This workaround is needed for when the drag ends on the (moved) srcElement
        // since the browser thinks that the drop failed in this case.
        dropEffect = 'success'  // anything other than 'none' here will work.
      }
      props.onToolDragEnd({ toolId: props.toolId, dropEffect })
      setTimeout(() => {
        forceRedraw.value()
      }, 100)
    }

    return {
      isDragging,
      onDragStart,
      onDrag,
      onDragEnd,
      onResizeBottomLeft,
      onResizeBottomRight,
      onResizeTopLeft,
      onResizeTopRight,
      onResizeLeft,
      onResizeRight,
      onResizeBottom,
      onResizeTop,
      onResizeEnd,
    }
  }
}
