// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

export const getNotesToolScrollY = (parentDiv) => {
  return parentDiv.children[1].firstElementChild.children[1].firstElementChild.scrollTop
}

export const setNotesToolScrollY = (parentDiv, scrollTop) => {
  setTimeout(() => {
    parentDiv.children[1].firstElementChild.children[1].firstElementChild.scrollTop = scrollTop
  }, 50)
}
