// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

export const getChatToolScrollY = (parentDiv) => {
  return parentDiv.children[1].firstElementChild.firstElementChild.scrollTop
}

export const setChatToolScrollY = (parentDiv, scrollTop) => {
  setTimeout(() => {
    parentDiv.children[1].firstElementChild.firstElementChild.scrollTop = scrollTop
  }, 50)
}
