// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()
    const featureToggles = computed(() => store.getters.featureToggles)

    const importTool = (baseName) => defineAsyncComponent(() => import(`@/components/tools/${baseName}/${baseName}.vue`))

    const toolNames = computed(() => {
      const chatToolOrEmpty = (featureToggles.value && featureToggles.value.enableSessionSharing) ? ['ChatTool'] : []
      return [
        'BlackBoxSSRTool',
        ...chatToolOrEmpty,
        'NotesTool',
        'UnknownTool',
        'ViewerTestTool',
        'ViewerTool',
      ]
    })

    const toolLookup = toolNames.value.reduce((map, name) => {
      map[name] = importTool(name)
      return map
    }, {})

    const toolComponent = (name) => {
      return toolLookup[name] || toolLookup['UnknownTool']
    }

    return {
      toolComponent,
    }
  }
}
