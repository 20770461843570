<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 496"
    :fill="toolIconColor"
  >
    <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
    <path d="M475.515 20.485 423.35 72.65C378.475 27.767 316.485 0 248 0 115.228 0 6.824 104.338.31 235.493-.029 242.311 5.475 248 12.301 248h28.045c6.353 0 11.613-4.952 11.973-11.294C58.161 133.649 143.453 52 248 52c54.163 0 103.157 21.923 138.614 57.386l-54.128 54.129c-7.56 7.56-2.206 20.485 8.485 20.485H484c6.627 0 12-5.373 12-12V28.971c0-10.691-12.926-16.045-20.485-8.486ZM483.699 248h-28.045c-6.353 0-11.613 4.952-11.973 11.294C437.839 362.351 352.547 444 248 444c-54.163 0-103.157-21.923-138.614-57.386l54.128-54.129c7.56-7.56 2.206-20.485-8.485-20.485H12c-6.627 0-12 5.373-12 12v143.029c0 10.691 12.926 16.045 20.485 8.485L72.65 423.35C117.525 468.233 179.516 496 248 496c132.773 0 241.176-104.338 247.69-235.493.339-6.818-5.165-12.507-11.991-12.507Z" />
  </svg>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

export default {
  setup() {
    const store = useStore()

    const toolIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].toolIconColor
    })

    const tr = inject('tr')

    return {
      toolIconColor,
      tr,
    }
  }
}
</script>