// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

export const getBlackBoxSSRToolScrollY = (parentDiv) => {
  if (!parentDiv ||
    parentDiv.children.length < 2 ||
    !parentDiv.children[1].firstElementChild ||
    !parentDiv.children[1].firstElementChild.firstElementChild.contentDocument ||
    !parentDiv.children[1].firstElementChild.firstElementChild.contentDocument.firstElementChild) { return 0 }
  return parentDiv.children[1].firstElementChild.firstElementChild.contentDocument.firstElementChild.scrollTop
}

export const setBlackBoxSSRToolScrollY = (parentDiv, scrollTop) => {
  setTimeout(() => {
    if (!parentDiv ||
      parentDiv.children.length < 2 ||
      !parentDiv.children[1].firstElementChild ||
      !parentDiv.children[1].firstElementChild.firstElementChild.contentDocument ||
      !parentDiv.children[1].firstElementChild.firstElementChild.contentDocument.firstElementChild) { return }
    parentDiv.children[1].firstElementChild.firstElementChild.contentDocument.firstElementChild.scrollTop = scrollTop
  }, 50)
}
