<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 496"
    :fill="modalIconColor"
  >
    <title>{{ tr('Configure tool') }}</title>
    <path d="m444.515 229 31.843-18.381c9.426-5.441 13.996-16.542 11.177-27.054-11.404-42.531-33.842-80.547-64.058-110.797-7.68-7.688-19.575-9.246-28.985-3.811l-31.785 18.358a196.276 196.276 0 0 0-32.9-19.02V31.542a24.016 24.016 0 0 0-17.841-23.206c-41.761-11.107-86.117-11.121-127.93-.001-10.52 2.798-17.844 12.32-17.844 23.206v36.753a196.276 196.276 0 0 0-32.9 19.02l-31.784-18.358c-9.41-5.435-21.305-3.877-28.985 3.81-30.216 30.25-52.654 68.266-64.058 110.798-2.82 10.512 1.75 21.613 11.177 27.054L51.485 229a197.715 197.715 0 0 0 0 37.999l-31.843 18.382c-9.426 5.44-13.996 16.542-11.177 27.054 11.404 42.53 33.842 80.547 64.058 110.797 7.68 7.688 19.575 9.246 28.985 3.81l31.785-18.357a196.202 196.202 0 0 0 32.899 19.019v36.753a24.016 24.016 0 0 0 17.842 23.206c41.76 11.107 86.117 11.122 127.93 0 10.519-2.797 17.844-12.32 17.844-23.205v-36.753a196.34 196.34 0 0 0 32.899-19.02l31.785 18.359c9.41 5.435 21.305 3.877 28.985-3.811 30.216-30.25 52.654-68.266 64.058-110.797 2.819-10.512-1.751-21.613-11.177-27.054L444.515 267c1.22-12.65 1.22-25.35 0-38zm-52.68 63.02 43.82 25.289a200.138 200.138 0 0 1-33.85 58.528l-43.828-25.31c-31.984 27.398-36.66 30.078-76.168 44.03v50.599a200.917 200.917 0 0 1-67.618 0v-50.6c-39.504-13.95-44.196-16.641-76.168-44.028l-43.83 25.309a200.15 200.15 0 0 1-33.848-58.528l43.819-25.29c-7.63-41.298-7.634-46.718 0-88.037l-43.82-25.29c7.85-21.228 19.31-41.048 33.85-58.528l43.829 25.309c31.984-27.397 36.66-30.078 76.168-44.03V50.847a200.917 200.917 0 0 1 67.618 0v50.599c39.504 13.95 44.196 16.642 76.168 44.029l43.829-25.31a200.143 200.143 0 0 1 33.849 58.53l-43.82 25.289c7.632 41.3 7.635 46.718 0 88.037zM248 152c-52.935 0-96 43.066-96 96 0 52.936 43.065 96 96 96s96-43.064 96-96c0-52.934-43.065-96-96-96zm0 144c-26.468 0-48-21.531-48-48 0-26.466 21.532-48 48-48s48 21.534 48 48c0 26.469-21.532 48-48 48z" />
  </svg>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js';

export default {
  setup() {
    const store = useStore()

    const modalIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].toolIconColor
    })

    const tr = inject('tr')

    return {
      modalIconColor,
      tr,
    }
  }
}
</script>