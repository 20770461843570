<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320.005 320.005"
    :fill="modalIconColor"
  >
    <title>{{ tr('Close tool') }}</title>
    <path d="m207.597 160.002 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0l-107.71 107.73L52.278 4.683c-6.23-6.23-16.34-6.23-22.58 0l-25.02 25.02c-6.23 6.23-6.23 16.34 0 22.58l107.72 107.72-107.72 107.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0l107.71-107.73 107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58z" />
  </svg>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js';

export default {
  setup() {
    const store = useStore()

    const modalIconColor = computed(() => {
      return themeColors[store.getters.currentThemeName].toolIconColor
    })

    const tr = inject('tr')

    return {
      modalIconColor,
      tr,
    }
  }
}
</script>