<template>
  <div class="tool-enclosure" :class="toolIdClass" :style="toolEnclosureStyle">
    <div class="tool-dragbar" :draggable="!fullscreen && !isSessionReadOnly" :ondragstart="onDragStart" :ondrag="onDrag"
      :ondragend="onDragEnd" :style="toolHeaderStyle" @dblclick.prevent="onDoubleClick">
      <span class="tool-dragbar-tool-info" :style="toolHeaderInfoStyle">{{ toolName ? toolName[language] : '' }}</span>
      <span class="tool-dragbar-patient-info" v-if="featureToggles.researcherView && payload.patient"
        :style="toolHeaderInfoStyle">{{ patientInfo }}</span>
      <reload-tool-icon class="tool-reload" @click.prevent="onReloadTool" role="button" tabindex="0"
        @keyup="onReloadTool">
      </reload-tool-icon>
      <configure-tool-icon v-if="payload.settings && !isSessionReadOnly" class="tool-dragbar-configure"
        @click.prevent="onConfigure" role="button" tabindex="0" @keyup.enter="onConfigure"></configure-tool-icon>
      <tool-exit-full-screen-icon v-if="fullscreen" class="tool-fullscreen-configure"
        @click.prevent="[onExitFullScreen(),$emit('fullscreen',false)]" role="button" tabindex="0"
        @keyup.enter="onExitFullScreen"></tool-exit-full-screen-icon>
      <div v-else>
        <tool-full-screen-icon class="tool-fullscreen-configure"
          @click.prevent="[onEnterFullScreen(),$emit('fullscreen', true)]" role="button" tabindex="0"
          @keyup.enter="onEnterFullScreen"></tool-full-screen-icon>
        <close-tool-icon v-if="!isSessionReadOnly" class="tool-dragbar-close" @click.prevent="onClose" role="button"
          tabindex="0" @keyup.enter="onClose"></close-tool-icon>
      </div>
    </div>

    <div class="tool-container" :style="toolContainerStyle" ref="toolContainerRef">
      <slot></slot>
    </div>

    <div class="resize-left-line-bottom-corner" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeBottomLeft" :ondragend="onResizeEnd"></div>
    <div class="resize-left-line-top-corner" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeTopLeft" :ondragend="onResizeEnd"></div>
    <div class="resize-bottom-line-left-corner" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeBottomLeft" :ondragend="onResizeEnd"></div>
    <div class="resize-bottom-line-right-corner" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeBottomRight" :ondragend="onResizeEnd"></div>
    <div class="resize-right-line-bottom-corner" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeBottomRight" :ondragend="onResizeEnd"></div>
    <div class="resize-right-line-top-corner" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeTopRight" :ondragend="onResizeEnd"></div>
    <div class="resize-top-line-left-corner" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeTopLeft" :ondragend="onResizeEnd"></div>
    <div class="resize-top-line-right-corner" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeTopRight" :ondragend="onResizeEnd"></div>
    <div class="resize-left-line" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeLeft" :ondragend="onResizeEnd"></div>
    <div class="resize-right-line" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeRight" :ondragend="onResizeEnd"></div>
    <div class="resize-bottom-line" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeBottom" :ondragend="onResizeEnd"></div>
    <div class="resize-top-line" :draggable="!fullscreen && !isSessionReadOnly"
      :style="{ cursor: fullscreen || isSessionReadOnly ? 'default' : null }" :ondragstart="onDragStart"
      :ondrag="onResizeTop" :ondragend="onResizeEnd"></div>
  </div>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject, ref } from 'vue'
import { useStore } from 'vuex'

import CloseToolIcon from '@/components/ui/icons/CloseToolIcon.vue'
import ConfigureToolIcon from '@/components/ui/icons/ConfigureToolIcon.vue'
import ReloadToolIcon from '@/components/ui/icons/ReloadToolIcon.vue'
import ToolExitFullScreenIcon from '@/components/ui/icons/ToolExitFullScreenIcon.vue'
import ToolFullScreenIcon from '@/components/ui/icons/ToolFullScreenIcon.vue'
import { convertRemToPixels, themeColors } from '@/common/shared.js'
import styleVariables from './BaseTool.vue?vue&type=style&index=0&lang=scss&module=1' // SCSS exports in style block of this file

import drag from './drag.js'
import placement from './placement.js'

export default {
  components: {
    CloseToolIcon,
    ConfigureToolIcon,
    ReloadToolIcon,
    ToolExitFullScreenIcon,
    ToolFullScreenIcon
  },
  props: {
    anonymized: {
      type: Boolean,
      default: false,
    },
    toolCol: {
      type: Number,
      required: true,
    },
    toolRow: {
      type: Number,
      required: true,
    },
    toolWidthInColumns: {
      type: Number,
      required: true,
    },
    toolHeightInRows: {
      type: Number,
      required: true,
    },
    columnSize: {
      type: Number,
      required: true,
    },
    getToolScrollY: {
      type: Function,
      required: true,
    },
    setToolScrollY: {
      type: Function,
      required: true,
    },
    // Do NOT pass sessionContainerHeight, as it destroys interactivity.
    // sessionContainerHeight: {
    //   type: Number,
    //   required: true,
    // },
    windowHeight: {
      type: Number,
      required: true,
    },
    windowWidth: {
      type: Number,
      required: true,
    },
    payload: {
      type: Object,
      required: false,
    },
    sessionId: {
      type: String,
      required: true,
    },
    sessionOwnerId: {
      type: String,
      required: true,
    },
    toolId: {
      type: String,
      required: true,
    },
    toolName: {
      type: Object,
      required: false,
    },
    onToolClose: {
      type: Function,
      required: true,
    },
    onToolDragStart: {
      type: Function,
      required: true,
    },
    onToolDrag: {
      type: Function,
      required: true,
    },
    onToolDragEnd: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const isSessionReadOnly = computed(() => store.getters.isSessionReadOnly)
    const language = computed(() => store.getters.profile.language)

    const heightInPixels = ref(0)
    const left = ref(0)
    const top = ref(0)
    const widthInPixels = ref(0)
    const fullscreen = ref(false)

    const toolIdClass = computed(() => {
      return `tool-id-${props.toolId}`
    })

    const isDragging = ref(false)

    const { forceRedraw } = placement.setup({ isDragging, top, left, heightInPixels, widthInPixels, fullscreen, props })

    const toolEnclosureStyle = computed(() => {
      const boxShadow = isDragging.value ? `16px 16px 16px 0px rgba(0, 0, 0, 0.5)` : `3px 3px 5px 3px rgba(0, 0, 0, 0.5)`
      return {
        left: `${left.value}px`,
        top: `${top.value}px`,
        width: `${widthInPixels.value}px`,
        height: `${heightInPixels.value}px`,
        zIndex: fullscreen.value || isDragging.value ? 100 : 0,
        boxShadow: boxShadow,
        borderColor: themeColors[store.getters.currentThemeName].toolBorderColor,
        transition: isDragging.value ? 'none' : null,
      }
    })

    const toolContainerStyle = computed(() => {
      const height = heightInPixels.value - convertRemToPixels(parseFloat(styleVariables.toolHeaderHeight)) - 2 * parseFloat(styleVariables.toolBorderWidth)
      return {
        height: `${height}px`,
      }
    })

    const toolHeaderStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].toolHeaderBackgroundColor,
        cursor: fullscreen.value || isSessionReadOnly.value ? 'default' : null,
      }
    })

    const toolHeaderInfoStyle = computed(() => {
      return {
        color: themeColors[store.getters.currentThemeName].toolHeaderTextColor,
      }
    })

    const onClose = () => props.onToolClose({ toolId: props.toolId })

    const onConfigure = () => console.log('BaseTool: onConfigure not implemented yet.')

    const {
      onDragStart,
      onDrag,
      onDragEnd,
      onResizeBottomLeft,
      onResizeBottomRight,
      onResizeTopLeft,
      onResizeTopRight,
      onResizeLeft,
      onResizeRight,
      onResizeBottom,
      onResizeTop,
      onResizeEnd,
    } = drag.setup({ isDragging, top, left, heightInPixels, widthInPixels, fullscreen, forceRedraw, props })

    const tr = inject('tr')

    const patientInfo = computed(() => {
      if (props.anonymized) {
        return `${tr.value('MRN')} ${props.payload.patient.id}`
      }
      return `${tr.value('MRN')} ${props.payload.patient.id}: ${props.payload.patient.name}`
    })

    const onReloadTool = computed(() => store.getters.onReloadTool(props.toolId))

    const onEnterFullScreen = () => {
      fullscreen.value = true
    }

    const onExitFullScreen = () => {
      fullscreen.value = false
    }

    const featureToggles = computed(() => store.getters.featureToggles)

    const toolContainerRef = ref(null)
    const onDoubleClick = () => {
      const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement
      if (!fullscreenElement) {
        if (toolContainerRef.value?.requestFullscreen) {
          toolContainerRef.value.requestFullscreen()
        } else if (toolContainerRef.value?.webkitRequestFullscreen) {
          toolContainerRef.value.webkitRequestFullscreen()
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
      }
    }

    return {
      featureToggles,
      fullscreen,
      isSessionReadOnly,
      language,
      onClose,
      onConfigure,
      onDoubleClick,
      onDrag,
      onDragEnd,
      onDragStart,
      onEnterFullScreen,
      onExitFullScreen,
      onReloadTool,
      onResizeBottom,
      onResizeBottomLeft,
      onResizeBottomRight,
      onResizeEnd,
      onResizeLeft,
      onResizeRight,
      onResizeTop,
      onResizeTopLeft,
      onResizeTopRight,
      patientInfo,
      toolContainerRef,
      toolContainerStyle,
      toolEnclosureStyle,
      toolHeaderInfoStyle,
      toolHeaderStyle,
      toolIdClass,
      tr,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/shared.scss';

$corner-resize-size: 30px;
$resize-size-extent-inside: 1px;
$resize-size-extent-outside: 7px;
$dragbar-height: 2rem;
$border-width: 1px;

$bottom-resize-bar-width: calc(100% - 2 * #{$corner-resize-size});
$close-icon-size: calc(0.375 * #{$dragbar-height});
$close-icon-position: calc(0.5 * (#{$dragbar-height} - #{$close-icon-size}));
$drag-size-full-extent: calc(#{$resize-size-extent-inside} + #{$resize-size-extent-outside});
$extended-corner-drag-size: calc(#{$corner-resize-size} + #{$resize-size-extent-outside});
$neg-corner-resize-size: calc(-1 * #{$corner-resize-size});
$neg-resize-size-extent-outside: calc(-1 * #{$resize-size-extent-outside});
$side-resize-bar-height: calc(100% - #{$dragbar-height} - #{$corner-resize-size});
$top-resize-bar-width: $bottom-resize-bar-width;

:export {
  toolBorderWidth: $border-width;
  toolHeaderHeight: $dragbar-height;
}

.tool-enclosure {
  border: $border-width solid black;
  box-shadow: $shadow-offset-x $shadow-offset-y $shadow-blur-radius $shadow-spread-radius rgba(0, 0, 0, $shadow-color-alpha);
  position: absolute;
}

.tool-dragbar {
  background-color: #ccc;
  cursor: move;
  height: $dragbar-height;
  overflow: clip;
  position: relative;
  width: 100%;
}

.tool-dragbar-close,
.tool-dragbar-configure,
.tool-fullscreen-configure,
.tool-reload {
  cursor: pointer;
  height: $close-icon-size;
  position: absolute;
  right: $close-icon-position;
  top: $close-icon-position;
  width: $close-icon-size;

  &:hover,
  &:focus {
    transform: scale(1.2);
  }

  &:focus:not(:focus-visible) {
    transform: scale(1);

    &:hover {
      transform: scale(1.2);
    }
  }
}

$tool-icon-right-spacing-factor: 2.4;

.tool-reload {
  right: calc(#{$close-icon-position} + #{$tool-icon-right-spacing-factor} * #{$close-icon-size} * 2);
}

.tool-dragbar-configure {
  right: calc(#{$close-icon-position} + 2 * #{$tool-icon-right-spacing-factor} * #{$close-icon-size});
}

.tool-fullscreen-configure {
  right: calc(#{$close-icon-position} + #{$tool-icon-right-spacing-factor} * #{$close-icon-size});
}

.tool-dragbar-patient-info {
  color: black;
  font-size: 1.4rem;
  cursor: pointer;

  &:hover {
    font-size: 1.6rem;
  }
}

.tool-dragbar-tool-info {
  color: black;
  font-size: 1.4rem;
  padding-right: calc(#{$close-icon-position} + #{$tool-icon-right-spacing-factor} * #{$close-icon-size} * 3)
}

.tool-container {
  position: relative;
  width: 100%;
}

.resize-right-line {
  cursor: e-resize;
  height: $side-resize-bar-height;
  position: absolute;
  right: $neg-resize-size-extent-outside;
  top: $dragbar-height;
  width: $drag-size-full-extent;
}

.resize-right-line-bottom-corner {
  cursor: se-resize;
  height: $corner-resize-size;
  position: absolute;
  right: $neg-resize-size-extent-outside;
  bottom: 0;
  width: $drag-size-full-extent;
}

.resize-right-line-top-corner {
  cursor: ne-resize;
  height: $corner-resize-size;
  position: absolute;
  right: $neg-resize-size-extent-outside;
  top: 0;
  width: $drag-size-full-extent;
}

.resize-left-line {
  cursor: w-resize;
  height: $side-resize-bar-height;
  left: $neg-resize-size-extent-outside;
  position: absolute;
  top: $dragbar-height;
  width: $drag-size-full-extent;
}

.resize-left-line-bottom-corner {
  cursor: sw-resize;
  height: $corner-resize-size;
  left: $neg-resize-size-extent-outside;
  position: absolute;
  bottom: 0;
  width: $drag-size-full-extent;
}

.resize-left-line-top-corner {
  cursor: nw-resize;
  height: $corner-resize-size;
  left: $neg-resize-size-extent-outside;
  position: absolute;
  top: 0;
  width: $drag-size-full-extent;
}

.resize-bottom-line {
  bottom: $neg-resize-size-extent-outside;
  cursor: s-resize;
  height: $drag-size-full-extent;
  left: $corner-resize-size;
  position: absolute;
  width: $bottom-resize-bar-width;
}

.resize-bottom-line-left-corner {
  bottom: $neg-resize-size-extent-outside;
  cursor: sw-resize;
  height: $drag-size-full-extent;
  left: $neg-resize-size-extent-outside;
  position: absolute;
  width: $extended-corner-drag-size;
}

.resize-bottom-line-right-corner {
  bottom: $neg-resize-size-extent-outside;
  cursor: se-resize;
  height: $drag-size-full-extent;
  position: absolute;
  right: $neg-resize-size-extent-outside;
  width: $extended-corner-drag-size;
}

.resize-top-line {
  top: $neg-resize-size-extent-outside;
  cursor: n-resize;
  height: $drag-size-full-extent;
  left: $corner-resize-size;
  position: absolute;
  width: $top-resize-bar-width;
}

.resize-top-line-left-corner {
  top: $neg-resize-size-extent-outside;
  cursor: nw-resize;
  height: $drag-size-full-extent;
  left: $neg-resize-size-extent-outside;
  position: absolute;
  width: $extended-corner-drag-size;
}

.resize-top-line-right-corner {
  top: $neg-resize-size-extent-outside;
  cursor: ne-resize;
  height: $drag-size-full-extent;
  position: absolute;
  right: $neg-resize-size-extent-outside;
  width: $extended-corner-drag-size;
}
</style>
